const serviceApp = process.env.VUE_APP_AFFILIATE_SERVICE || 'AFFILIATE';
const serviceAppAdvertiser = process.env.VUE_APP_ADVERTISER_SERVICE || 'ADVERTISER';

export default {
  methods: {
    async getListChannels() {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/v2/affiliate/site/list`);
    },

    async getIdNameChannelsByManager(managerId) {
      return await this.$http.get(`/redirect?app=${serviceApp}&path=/api/get/channelsIdAndNameByManagerId/${managerId}`);
    },

    async getCampaignsByOperationalManager(managerId) {
        return await this.$http.get(`/redirect?app=${serviceAppAdvertiser}&path=/api/get/campaignsByOperationalManager/${managerId}`);
    }
  }
};
