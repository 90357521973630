<template>
    <q-card flat class="q-pa-md q-ma-md">
      <q-form @submit.prevent="includeSites">
        <div class="q-pa-sm row justify-around q-gutter-x-sm">
          <q-card flat bordered class="col">
            <q-card-section class="bg-primary text-white">
              <div class="top-card-label">Selecione os canais</div>
            </q-card-section>

            <q-card-section>
              <q-select
                v-model="sites"
                label="Escolha um canal..."
                multiple
                use-chips
                outlined
                dense
                use-input
                input-debounce="200"
                @filter="filterSites"
                :options="sitesFiltered"
                option-label="name"
                option-value="id"
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    removable
                    size="0.8rem"
                    @remove="scope.removeAtIndex(scope.index)"
                    :tabindex="scope.tabindex"
                    color="primary"
                    text-color="white"
                    square
                    icon-remove="close"
                    input-class="text-caption"
                  >
                    {{ scope.opt.name }}
                  </q-chip>
                </template>
              </q-select>
            </q-card-section>

            <q-card-section class="row items-center q-gutter-sm">
              <q-checkbox
                v-model="isManualSite"
                label="Manual"
                color="primary"
                @update:model-value="toggleSelectModeSites('manual')"
              />
              <q-checkbox
                v-model="isAffiliateManager"
                label="Por gerente de afiliado"
                color="primary"
                @update:model-value="toggleSelectModeSites('affiliate')"
              />
            </q-card-section>

            <q-card-section v-if="isAffiliateManager">
              <q-select
                v-model="selectedAffiliateManager"
                label="Escolha um gerente..."
                outlined
                dense
                use-input
                input-debounce="200"
                :options="affiliateManagersList"
                option-label="name"
                option-value="id"
                color="primary"
                @input="onManagerSelected"
                clearable
              />
            </q-card-section>

            <q-card-section class="scroll">
              <q-list bordered v-show="sites.length > 0" class="scroll" style="max-height: 200px">
                <q-item v-for="(item, index) in sites" :key="index">
                  <q-item-section>
                    <q-item-label>{{ item.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-btn icon="mdi-close" flat dense round @click="removeSite(index)" />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>

          <q-card flat bordered class="col">
            <q-card-section class="bg-purple text-white">
              <div class="top-card-label">Selecione as campanhas</div>
            </q-card-section>

            <q-card-section>
              <q-select
                v-model="campaigns"
                label="Escolha uma campanha..."
                multiple
                use-chips
                outlined
                dense
                use-input
                input-debounce="200"
                @filter="filterCampaigns"
                :options="campaignsFiltered"
                option-label="name"
                option-value="id"
                color="purple"
              >
                <template v-slot:selected-item="scope">
                  <q-chip
                    removable
                    size="0.8rem"
                    @remove="scope.removeAtIndex(scope.index)"
                    :tabindex="scope.tabindex"
                    color="purple"
                    text-color="white"
                    square
                    icon-remove="close"
                    input-class="text-caption"
                  >
                    {{ scope.opt.name }}
                  </q-chip>
                </template>
              </q-select>
            </q-card-section>

            <q-card-section class="row items-center q-gutter-sm">
              <q-checkbox
                v-model="isManualCampaign"
                label="Manual"
                color="primary"
                @update:model-value="toggleSelectModeCampaign('manual2')"
              />
              <q-checkbox
                v-model="isCampaignManager"
                label="Por gerente de campanha"
                color="primary"
                @update:model-value="toggleSelectModeCampaign('campaign')"
              />
            </q-card-section>

            <q-card-section v-if="isCampaignManager">
              <q-select
                v-model="selectedCampaignManager"
                label="Escolha um gerente..."
                outlined
                dense
                use-input
                input-debounce="200"
                :options="CampaignManagersList"
                option-label="name"
                option-value="id"
                color="primary"
                @input="onCampaignManagerSelected"
                clearable
              />
            </q-card-section>

            <q-card-section class="scroll">
              <q-list bordered v-show="campaigns.length > 0" class="scroll" style="max-height: 200px">
                <q-item v-for="(item, index) in campaigns" :key="index">
                  <q-item-section>
                    <q-item-label>{{ item.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-btn icon="mdi-close" flat dense round @click="removeCampaign(index)" />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
        </div>

        <q-card-section class="text-center">
          <q-btn unelevated no-caps type="submit" color="positive" label="Aprovar Canais" />
        </q-card-section>
      </q-form>
    </q-card>
  </template>

<script>
import ChannelService from '../../services/ChannelService';

export default {
  mixins: [ ChannelService ],

  data() {
    return {
      sites: [],
      sitesList: [],
      sitesFiltered: [],
      campaigns: [],
      campaignsList: [],
      campaignsFiltered: [],
      isManualSite: true,
      isManualCampaign: true,
      isAffiliateManager: false,
      isCampaignManager: false,
      affiliateManagers: [],
      campaignManagers: [],
      affiliateManagersList: [],
      CampaignManagersList: [],
      selectedAffiliateManager: null,
      selectedCampaignManager: null,
    };
  },
  watch: {
    isManualSite(val) {
        if (val) this.isAffiliateManager = false;
    },
    isAffiliateManager(val) {
        if (val) this.isManualSite = false;
    },
    isManualCampaign(val) {
        if (val) this.isCampaignManager = false;
    },
    isCampaignManager(val) {
        if (val) this.isManualCampaign = false;
    },
  },

  methods: {
    onManagerSelected(manager) {
      if (!manager) {
        this.sites = [];
        this.sitesFiltered = [];
      } else {
        this.updateChannelsByManager(manager.id);
      }
    },

    onCampaignManagerSelected(manager) {
      if (!manager) {
        this.campaigns = [];
        this.campaignsFiltered = [];
      } else {
        this.updateCampaignsByManager(manager.id);
      }
    },

    async updateChannelsByManager(managerId) {
      if (!managerId) return;

      try {
          this.onLoading(true);
          const { data, status } = await this.getIdNameChannelsByManager(managerId);
          if (status === 200) {
          this.sites = data.map(site => ({
              ...site,
              name: `${site.name} (id: ${site.id})`
          }));
          this.sitesFiltered = [...this.sites];
          }
      } catch (error) {
        this.errorNotify('Erro ao carregar canais do gerente:', error);
      } finally {
          this.onLoading(false);
      }
    },

    async updateCampaignsByManager(managerId) {
      if (!managerId) return;

      try {
          this.onLoading(true);
          const { data, status } = await this.getCampaignsByOperationalManager(managerId);
          if (status === 200) {
            this.campaigns = data.map(campaign => ({
              ...campaign,
              name: `${campaign.name} (id: ${campaign.id})`
            }));
            this.campaignsFiltered = [...this.campaignsList];
          }
      } catch (error) {
          this.errorNotify('Erro ao carregar canais do gerente:', error);
      } finally {
          this.onLoading(false);
      }
    },

    async getSites() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getListChannels();
          if (status === 200) {
            this.sitesList = data.map(site => ({
                ...site,
                name: `${site.name} (id: ${site.id})`
            }));
            this.sitesFiltered = [...this.sitesList];
          }
      } catch (error) {
        console.error(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterSites(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.sitesFiltered = !needle
        ? this.sitesList
        : this.sitesList.filter(v => v.name.toLocaleLowerCase().includes(needle) || v.id.toString().includes(needle));
      });
    },

    async getCampaigns() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/campaign/allactive_and_pending');
          if (status === 200) {
            this.campaignsList = data.map(campaign => ({
              ...campaign,
              name: `${campaign.name} (id: ${campaign.id})`
            }));
            this.campaignsFiltered = [...this.campaignsList];
          }
      } catch (error) {
        console.error(error);
      }
    },

    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        this.campaignsFiltered = !needle
        ? this.campaignsList
        : this.campaignsList.filter(v => v.name.toLocaleLowerCase().includes(needle) || v.id.toString().includes(needle));
      });
    },

    async includeSites() {
      if (this.sites.length == 0 || this.campaigns.length == 0) {
        this.errorNotify('Preencha ao menos um canal e uma campanha');
        return;
      }

      try {
        this.onLoading(true);

        let request = {
          app: 'ADVERTISER',
          path: '/api/campaign/registra_canal',
          params: `{"data":  {
                        "site_ids": [${this.sites.map((item) => item.id)}],
                        "campaign_ids": [${this.campaigns.map((item) => item.id)}]
                    }}`
        };

        const { data, status } = await this.$http.post('/redirect', this.parseQs(request));
        if (status === 200) {
          this.successNotify(data.message);

          this.sites = [];
          this.campaigns = [];
          this.sitesFiltered = [];
          this.campaignsFiltered = [];
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    removeCampaign(index) {
      this.campaigns.splice(index, 1);
    },
    removeSite(index) {
      this.sites.splice(index, 1);
    },

    toggleSelectModeSites(mode) {
      if (mode === "manual") {
          this.isManualSite = true;
          this.isAffiliateManager = false;
      } else if (mode === "affiliate") {
          this.isManualSite = false;
          this.isAffiliateManager = true;
      }
    },

    toggleSelectModeCampaign(mode) {
      if (mode === "manual2") {
          this.isManualCampaign = true;
          this.isCampaignManager = false;
      } else if (mode === "campaign") {
          this.isManualCampaign = false;
          this.isCampaignManager = true;
      }
    },

    async getManagersRedes() {
      try {
          const { data, status } = await this.$http.get(`/user/affiliate/managers/redes`);
          if (status === 200) {
              this.affiliateManagersList = data.map(manager => ({
                  id: manager.user_id,
                  name: manager.name
              }));
          }
      } catch (error) {
        this.errorNotify('Erro ao carregar gerentes de afiliados:', error);
      }
    },

    async getManagersOperational() {
      try {
          const { data, status } = await this.$http.get(`user/managers-unblocked-operational`);
          if (status === 200) {
              this.CampaignManagersList = data.map(manager => ({
                  id: manager.user_id,
                  name: manager.name
              }));
          }
      } catch (error) {
          this.errorNotify('Erro ao carregar gerentes de campanhas:', error);
      }
    }

  },

  created() {
    this.getSites();
    this.getCampaigns();
    this.getManagersRedes();
    this.getManagersOperational();
  }
};
</script>
